import {
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ExpenseForm from "./ExpenseForm";

const AddExpense = () => {
  useEffect(()=>{
    document.title="Add Expense - DEMS"
  },[])
  return (
    <>
      <Typography fontSize={30} >Add Expense</Typography>
      <ExpenseForm mode="cash"/>
    </>
  );
};

export default AddExpense;
