import React from "react";

const Nopage = () => {
  return (
    <div>
      <h1>404 Error Not Found</h1>{" "}
    </div>
  );
};

export default Nopage;
