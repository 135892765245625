import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import AccordionList from './AccordionList'

const ShowExpenses = () => {
  useEffect(()=>{
    document.title="Show Expense - DEMS"
  },[])
  return (
    <>
      <Typography fontSize={30}>Manage Your Daily Expenses</Typography>
      <AccordionList/>
    </>
  )
}

export default ShowExpenses
