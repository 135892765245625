import {
  Typography,
} from "@mui/material";
import React,{useEffect} from "react";
import ExpenseForm from "./ExpenseForm";

const AddTransaction = () => {
  useEffect(()=>{
    document.title="Add Transaction - DEMS"
  },[])
  return (
    <>
      <Typography fontSize={30}>Add Transaction</Typography>
      <ExpenseForm mode="transaction"/>
    </>
  );
};

export default AddTransaction;
